import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Sell.css";

import Page1 from "../../assets/images/seller_page-0001.png";
import Page2 from "../../assets/images/seller_page-0002.png";
import Page3 from "../../assets/images/seller_page-0003.png";
import Page4 from "../../assets/images/seller_page-0004.png";
import SignatureCanvas from "react-signature-canvas";
import config from "../../config";
import StripeComponent from "./StripeComponent";
import successImage from "./../../assets/images/success.png";
import CloseIcon from "@mui/icons-material/Close";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { styled } from "@mui/system";
import Features from "./Features";
import {
  Box,
  Container,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
  Modal,
  IconButton,
  Backdrop,
  Fade,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
} from "@mui/material";
import SellWelcomeScreen from "./SellWelcomeScreen";
import PackagesList from "./PackagesList";
import SellImages from "../../components/SellImages";
import { isAddressValid } from "../../utils/GlobalFunctions";
import Header from "../../components/Header/Header";
import FormOne from "../../components/sell/FormOne";

const StyledTabs = styled(Tabs)({
  "& .MuiTab-root": {
    textTransform: "none",
    padding: "6px 20px",
    minWidth: 120,
    fontWeight: 500,
    borderRadius: "8px",
    border: "1px solid #e0e0e0",
    marginRight: "8px",
    color: "#344054",
    "&.Mui-selected": {
      backgroundColor: "#FF6D2B",
      color: "#fff",
      borderColor: "#FF6D2B",
    },
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});

const SignatureArea = styled("div")(({ fontFamily }) => ({
  border: "1px solid #ccc",
  padding: "20px",
  textAlign: "center",
  fontSize: "32px",
  fontFamily: fontFamily || "cursive",
  position: "relative",
  marginBottom: "20px",
  "&::before": {
    content: '""',
    display: "block",
    width: "100%",
    height: "2px",
    backgroundColor: "#000",
    position: "absolute",
    bottom: "10px",
    left: "0",
  },
}));

function Sell() {
  const [orientation, setOrientation] = useState("");
  const sigCanvasRef = useRef();
  const [transaction_id, setTransactionId] = useState(null);
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [value, setValue] = useState("Single_Family");
  const [nextLoading, setNextLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [expandContract, setExpandContract] = useState(false);
  const [total_price, setTotal_price] = useState("");
  const [previewModal, setPreviewModal] = useState(null);
  const [data, setData] = useState({
    seller_address: "",
    latitude: 0.0,
    longitude: 0.0,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [imagesArr, setImagesArr] = useState([]);
  const [imagesForApi, setImagesForApi] = useState([]);
  const [labels, setLabels] = useState([]);
  const steps = [
    { label: "General Info" },
    { label: "Pictures" },
    { label: "Contract" },
    { label: "Payment Info" },
  ];
  const addressInputRef = useRef(null);
  const [signMethod, setSignMethod] = useState(0);
  const [isSigned, setIsSigned] = useState(false);
  const [typedSignature, setTypedSignature] = useState("");
  const [selectedFont, setSelectedFont] = useState("cursive");
  const [addressError, setAddressError] = useState("");
  const modalRef = useRef(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [selected_items, setSelected_items] = useState([]);
  const [package_type, setPackage_type] = useState('');
  const [initialLoad, setInitialLoad] = useState(false);

  const handleSignMethodChange = (event, newValue) => {
    setSignMethod(newValue);
    setIsSigned(false);
  };

  const handleTypedSignatureChange = (event) => {
    setTypedSignature(event.target.value);
    setIsSigned(event.target.value.trim().length > 0);
  };

  const handleFontChange = (event) => {
    setSelectedFont(event.target.value);
  };

  const handleCloseExpandContract = () => {
    setExpandContract(false);
    setZoomLevel(1);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 2));
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1));
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  const handleNext = async () => {
    try {
      let canProceed = true;

      if (activeStep === 1 && (!data.seller_address.trim() || !isAddressValid(data.seller_address))) {
        if (!data.seller_address.trim()) {
          showError("Address cannot be empty.");
        }
        if (!isAddressValid(data.seller_address)) {
          setAddressError('Address must be in MN, NC, HI or FL')
        }

        return;
      }

      // Step 1: Property details (address and type)
      if (activeStep === 1) {
        setNextLoading(true);

        const isSuccess = await addProperty({
          address: data.seller_address,
          PropertySubType: value,
          latitude: data.latitude,
          longitude: data.longitude,
        });
        setNextLoading(false);
        canProceed = isSuccess;
      }

      // Step 2: Upload Images
      if (activeStep === 2) {
        console.log("🚀 ~ handleNext ~ imagesArr.length:", imagesArr.length)
        if (imagesArr.length > 0) {
          setNextLoading(true);
          if (imagesForApi.length > 0) {
            console.log("🚀 ~ handleNext ~ agesForApi.length:", imagesForApi.length)
            const uploadSuccess = await uploadImages(); // Upload images, return success or failure
            if (!uploadSuccess) {
              showError("Failed to upload images. Please try again.");
              return; // Stop if image upload fails
            }
          }
        }
        setNextLoading(false);
        canProceed = true;
      }

      // Step 4: Contract (Signature) validation
      if (activeStep === 4 && !isSigned) {
        showError("Please sign the document before proceeding.");
        return;
      }

      if (activeStep === 4 && isSigned) {
        setNextLoading(true);
        const signatureSaved = await saveSignatureOnServer(); // Save signature, return success or failure
        if (!signatureSaved) {
          showError("Failed to save signature. Please try again.");
          return; // Stop if signature save fails
        }
        setNextLoading(false);
        canProceed = true;
      }

      // Only proceed to the next step if everything succeeded
      if (canProceed) {
        const isLastStep = activeStep === 5;
        if (!isLastStep || paymentSuccess) {
          setActiveStep((prevActiveStep) => prevActiveStep + 1); // Increment the step
        } else {
          console.log("Attempted to proceed without payment success on last step");
        }
        setErrorMessage("");
      }
    } catch (err) {
      showError(err.message);
    } finally {
      setNextLoading(false);
    }
  };

  const uploadImages = async () => {
    try {
      const accessToken = localStorage.getItem("authUser");
      const token = accessToken.replace(/^"|"$/g, "");

      const formData = new FormData();

      console.log("🚀 ~ imagesForApi.forEach ~ imagesForApi:", imagesForApi)
      imagesForApi.forEach((uri) => {
        formData.append("images", uri);
      });
      labels.forEach((label) => {
        formData.append("labels", label);
      });
      formData.append("transaction_id", transaction_id);

      const api_url = `${config.get("API_URL")}/api/user/properties/add/upload-image`;

      console.log("🚀 ~ uploadImages ~ api_url:", api_url)
      const response = await fetch(api_url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      console.log("RESPONSE")
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Images uploaded successfully", data);

      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error while uploading images:", error);
      return false; // Return false to indicate failure
    }
  };


  const handleBack = () => {
    if (activeStep === 2) {
      setIsSigned(false);
      if (sigCanvasRef.current) {
        sigCanvasRef.current.clear();
      }
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    if (activeStep === 2) {
      setIsSigned(false);
      if (sigCanvasRef.current) {
        sigCanvasRef.current.clear();
      }
    }
  }, [activeStep]);

  useEffect(() => {
    document.title = "List Now | AiRE Brokers";
  }, []);

  useEffect(() => {
    const loadGoogleMapsScript = () => {
      if (!window.google) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=${config.GOOGLE_API_KEY}&libraries=places`;
        console.log("Properties:", config.GOOGLE_API_KEY);
        script.async = true;
        script.defer = true;
        document.head.appendChild(script);

        script.onload = () => {
          initializeAutocomplete();
        };
      } else {
        initializeAutocomplete();
      }
    };

    const initializeAutocomplete = () => {
      if (addressInputRef.current) {
        console.log("Initialize auto complete")
        const autocomplete = new window.google.maps.places.Autocomplete(
          addressInputRef.current,
          {
            types: ["address"],
            componentRestrictions: { country: "us" },
          }
        );

        autocomplete.addListener("place_changed", () => {
          const place = autocomplete.getPlace();

          if (!place.geometry || !place.geometry.location) {
            console.error("Place object does not contain geometry or location");
            return;
          }

          const addressComponents = place.address_components;
          let street_number = "";
          let route = "";
          let locality = "";
          let state = "";
          let postal_code = "";
          let country = "";

          addressComponents.forEach((component) => {
            const types = component.types;
            if (types.includes("street_number")) {
              street_number = component.long_name;
            }
            if (types.includes("route")) {
              route = component.long_name;
            }
            if (types.includes("locality")) {
              locality = component.long_name;
            }
            if (types.includes("administrative_area_level_1")) {
              state = component.long_name;
            }
            if (types.includes("postal_code")) {
              postal_code = component.long_name;
            }
            if (types.includes("country")) {
              country = component.long_name;
            }
          });

          const formattedAddress = `${street_number} ${route}, ${locality}, ${state} ${postal_code}, ${country}`;
          const latitude = place.geometry.location.lat();
          const longitude = place.geometry.location.lng();

          console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);

          setData((prevData) => ({
            ...prevData,
            seller_address: formattedAddress,
            latitude: latitude,
            longitude: longitude,
          }));
        });
      }
    };
    if (addressInputRef.current && !initialLoad) {

      loadGoogleMapsScript();
      setInitialLoad(true)
    }
  }, [addressInputRef.current]);

  const onCheckProperty = async () => {
    fetchProperties();
  };

  const fetchProperties = async () => {
    const authUserToken = localStorage.getItem("authUser");
    const authUser = authUserToken ? authUserToken.replace(/^"|"$/g, "") : "";
    try {
      const response = await fetch(
        `${config.get("API_URL")}/api/user/properties/list`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${authUser}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      } else {
        console.log("Properties:", data);

        localStorage.setItem("propertiesData", JSON.stringify(data));
        let lastItem = data[data.length - 1];
        setPreviewModal(lastItem.property_id);
      }
    } catch (error) {
      console.error("Error fetching properties:", error);
    }
  };

  const handleClickOutside = (event) => {
    if (modalRef.current && !modalRef.current.contains(event.target)) {
      setExpandContract(false);
    }
  };

  useEffect(() => {
    if (expandContract) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [expandContract]);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const showError = (message) => {
    console.log(message);
    setErrorMessage(message);
    setOpenSnackbar(true);
  };

  const addProperty = async (body) => {
    try {
      const accessToken = localStorage.getItem("authUser");
      if (accessToken) {
        const token = accessToken.replace(/^"|"$/g, "");
        const addSellUrl = `${config.get(
          "API_URL"
        )}/api/user/properties/add/property-type-selection`;

        const response = await fetch(addSellUrl, {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(body),
        });

        const responseData = await response.json();
        if (responseData.error) throw Error(responseData.error);
        setTransactionId(responseData.transaction_id);
        localStorage.setItem("transaction_id", responseData.transaction_id);
        return responseData;
      }
    } catch (error) {
      console.log(error.message);
      throw Error(error.message);
    }
  };

  const saveSignatureOnServer = async () => {
    try {
      const accessToken = localStorage.getItem("authUser");
      const token = accessToken.replace(/^"|"$/g, "");

      let signature;

      if (signMethod === 0) {
        signature = sigCanvasRef.current.getTrimmedCanvas().toDataURL("image/png");
      } else if (signMethod === 1) {
        signature = typedSignature;
      }

      const body = {
        transaction_id: transaction_id,
        signature_data: signature,
      };

      const url = `${config.get("API_URL")}/api/user/properties/add/save-pdf`;
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
        credentials: "include",
      });

      if (!response.ok) {
        throw new Error(`Failed to save signature: ${response.status}`);
      }

      const responseData = await response.json();
      console.log("Signature saved successfully", responseData);
      return true; // Return true to indicate success
    } catch (error) {
      console.error("Error saving signature:", error);
      return false; // Return false to indicate failure
    }
  };


  const handlePaymentResponse = (status) => {
    if (status === 200) {
      setPaymentSuccess(true);
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSuccess(true);
    } else {
      console.log("Payment failed or incomplete");
    }
  };

  const drawImageOnCanvas = () => {
    const canvas = document.getElementById("page3-canvas");
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        canvas.width = img.width;
        canvas.height = canvas.width / aspectRatio;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
      };
      img.src = Page3;
    }
  };

  const drawSignatureonPageThree = () => {
    const canvas = document.getElementById("page3-canvas");
    if (canvas) {
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        const aspectRatio = img.width / img.height;
        canvas.width = img.width;
        canvas.height = canvas.width / aspectRatio;
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const x = canvas.width / 6.5;
        const y = canvas.height / 1.7;
        ctx.drawImage(sigCanvasRef.current.getCanvas(), x, y);
        ctx.font = "bold 30px Arial";
        ctx.fillStyle = "red";
        const dateX = canvas.width / 1.26;
        const dateY = canvas.height / 1.62;
        ctx.fillText(new Date().toLocaleDateString(), dateX, dateY);
      };
      img.src = Page3;
    }
  };

  useEffect(() => {
    drawImageOnCanvas();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 460) {
        setOrientation("vertical");
      } else if (window.innerWidth <= 992) {
        setOrientation("horizontal");
      } else {
        setOrientation("horizontal");
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleChangeAddress = (e) => {

    setData({
      ...data,
      seller_address: e.target.value,
    })
    setAddressError("")

  }

  return (
    <>
      <Header title="List Now" />
      <Container className="backgroundContainer pt-10">
        {(paymentLoading || success) && (
          <div className="form p-0 bg-[rgba(255,255,255,0.5)] h-[500px] backdrop-blur-sm rounded-xl">
            <div className="rounded-xl bg-primary-500 flex justify-center items-center h-[90px] ">
              <img
                src="/aire-brokers-logo03-1@2x.png"
                alt="logo"
                className="h-[70px]"
              />
            </div>
            <div className=" h-full -mt-[90px] flex items-center justify-center">
              <div className="flex flex-col gap-3 justify-center items-center">
                <h1 className="text-primary-500 text-[2rem] font-[600]">
                  {paymentLoading ? "Processing..." : "Great job!"}
                </h1>
                {paymentLoading ? (
                  <CircularProgress size={50} />
                ) : (
                  <span className="text-center font-roboto font-normal text-base leading-[19px] width-[50px] text-[#001659]">
                    Our review team is currently giving your listing the VIP
                    treatment!
                    <br /> We'll be in touch via the app and email shortly—stay
                    tuned for updates!
                  </span>
                )}
                {!paymentLoading && (
                  <button
                    onClick={onCheckProperty}
                    className="bg-orange-500 mt-5 rounded-md px-10 py-3 text-white"
                  >
                    Go to my properties
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
        {!success && (
          <div className={`${paymentLoading ? "hidden" : "block"} my-auto`}>
            {activeStep !== 0 && activeStep !== 3 &&
              <div className="flex justify-center w-full">
                <Box sx={{ width: "80%" }}>
                  <Stepper className="mt-5" activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => {
                      let currentStep = (activeStep === 1 || activeStep === 2) ? activeStep - 1 : activeStep === 4 ? 2 : activeStep === 5 ? 3 : activeStep
                      return (
                        <Step key={label.label}>
                          <StepLabel
                            StepIconProps={{
                              style: {
                                color: currentStep === index ? "#1ABC9C" : "#B2DFDB", // #B2DFDB is a lighter shade for inactive steps
                              },
                            }}
                          >
                            <p
                              style={{
                                color: currentStep === index ? "#1ABC9C" : "#4F4F4F", // Using a neutral gray for non-active steps
                              }}
                            >
                              {label.label}
                            </p>
                          </StepLabel>
                        </Step>
                      )
                    })}
                  </Stepper>
                </Box>
              </div>}
            {activeStep === 0 ?
              <SellWelcomeScreen setActiveStep={setActiveStep} /> :
              activeStep === 3 ? <PackagesList setSelected_items={setSelected_items} setPackage_type={setPackage_type} setTotal_price={setTotal_price} setActiveStep={setActiveStep} />
                :
                <form
                  onSubmit={handleSubmit}
                  className={` ${activeStep !== 3 ? "" : "grid-cols-[1fr,1fr,1fr] "
                    }  overflow-hidden justify-center w-full 
 `}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      gap: "10px",
                    }}
                  >
                    <Snackbar
                      open={openSnackbar}
                      autoHideDuration={6000}
                      onClose={handleCloseSnackbar}
                    >
                      <Alert
                        onClose={handleCloseSnackbar}
                        severity="error"
                        sx={{ width: "100%" }}
                      >
                        {errorMessage}
                      </Alert>
                    </Snackbar>

                    {activeStep === 1 && (
                      <FormOne
                        addressInputRef={addressInputRef}
                        data={data}
                        handleChangeAddress={handleChangeAddress}
                        addressError={addressError}
                        setValue={setValue}
                        value={value}
                      />
                    )}
                    {activeStep === 2 && (
                      <Container className="min-h-[60vh] overflow-auto mt-5">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            "&::after": {
                              content: '""',
                              flex: "auto",
                            },
                          }}
                        >
                          <div className="user_details">
                            <div className="input_pox">
                              <SellImages
                                transaction_id={transaction_id}
                                imagesArr={imagesArr}
                                imagesForApi={imagesForApi}
                                setImagesForApi={setImagesForApi}
                                setImagesArr={setImagesArr}
                                labels={labels}
                                setlabels={setLabels}
                              />
                            </div>
                          </div>
                        </Box>
                      </Container>
                    )}

                    {activeStep === 4 && (
                      <Container>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            flexWrap: "wrap",
                            padding: "2rem",
                            "&::after": {
                              content: '""',
                              flex: "auto",
                            },
                          }}
                        >
                          <div className="w-full flex flex-col xl:flex-row justify-between gap-5">
                            <div className="w-full xl:w-1/2">
                              <div>
                                <label
                                  className="datails datails font-[800] text-[#001659]"
                                  htmlFor="seller_address"
                                >
                                  E- Sign your Contract
                                </label>
                                <StyledTabs
                                  value={signMethod}
                                  onChange={handleSignMethodChange}
                                >
                                  <Tab label="Finger signing" />
                                  <Tab label="Type Sign" />
                                </StyledTabs>
                              </div>
                              <div className="my-5">
                                {signMethod === 0 && (
                                  <div>
                                    <p>Sign Here</p>
                                    <SignatureCanvas
                                      ref={sigCanvasRef}
                                      onEnd={() => {
                                        drawSignatureonPageThree();
                                        setIsSigned(true);
                                      }}
                                      penColor="#232D8E"
                                      canvasProps={{
                                        className: "sigCanvas",
                                        style: {
                                          height: 300,
                                          backgroundColor: "#f7f7f7",
                                        },
                                      }}
                                    />
                                  </div>
                                )}
                                {signMethod === 1 && (
                                  <div>
                                    <p>Type your signature:</p>
                                    <SignatureArea fontFamily={selectedFont}>
                                      {typedSignature || "Your Signature"}
                                    </SignatureArea>
                                    <input
                                      type="text"
                                      value={typedSignature}
                                      onChange={handleTypedSignatureChange}
                                      placeholder="Type your name"
                                      className="w-full p-2 border border-gray-300 rounded"
                                      style={{
                                        fontFamily: selectedFont,
                                        fontSize: "24px",
                                        textAlign: "center",
                                        padding: "10px",
                                      }}
                                    />
                                    <FormControl
                                      sx={{ mt: 2, width: "100%" }}
                                      variant="outlined"
                                    >
                                      <InputLabel id="font-select-label">
                                        Font
                                      </InputLabel>
                                      <Select
                                        labelId="font-select-label"
                                        value={selectedFont}
                                        onChange={handleFontChange}
                                        label="Font"
                                      >
                                        <MenuItem value="cursive">Cursive</MenuItem>
                                        <MenuItem value="serif">Serif</MenuItem>
                                        <MenuItem value="monospace">Monospace</MenuItem>
                                        <MenuItem value="sans-serif">Sans-serif</MenuItem>
                                      </Select>
                                    </FormControl>
                                  </div>
                                )}
                              </div>
                              <p className="mt-5 text-[#001659] text-[15px]">
                                By signing the above e-signature, I agree that such
                                signature will be as valid as handwritten signatures
                                to the extent allowed by local law.
                              </p>
                            </div>
                            <div className="w-full xl:w-1/2">
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  gap: "5rem",
                                }}
                              >
                                <label className="datails" htmlFor="seller_address">
                                  Contract PDF Preview
                                </label>
                                <button
                                  onClick={() => setExpandContract(true)}
                                  className="text-orange-500 bg-transparent border-0 p-0 underline cursor-pointer mb-2"
                                >
                                  Expand Contract
                                </button>
                              </div>

                              <div style={{ width: "100%" }}>
                                <div className="image-container" id="image-container">
                                  <img src={Page1} alt="" className="pdf-image" />
                                  <img src={Page2} alt="" className="pdf-image" />
                                  <canvas
                                    id="page3-canvas"
                                    className="pdf-image"
                                  ></canvas>
                                  <div
                                    className="seller-date"
                                    id="currentDate"
                                  ></div>
                                  <img src={Page4} alt="" className="pdf-image" />
                                </div>
                                <br />
                              </div>
                            </div>
                            <br />
                          </div>
                        </Box>
                      </Container>
                    )}

                    {activeStep === 5 && (
                      <>
                        <Container sx={{ padding: 0 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                sm: "row",
                              },
                              justifyContent: "center",
                              alignItems: "center",
                              flexWrap: "wrap",
                              padding: 0,
                              "&::after": {
                                content: '""',
                                flex: "auto",
                              },
                            }}
                          >
                            <div className="w-full xl:w-[70%]">
                              <StripeComponent
                                onPaymentSuccess={handlePaymentResponse}
                                onBack={handleBack}
                                total_price={total_price}
                                package_type={package_type}
                                selected_items={selected_items}
                                onSetLoading={setPaymentLoading}
                                setSuccess={setSuccess}
                                showError={showError}
                                fullName={(data.first_name, data.last_name)}
                              />
                            </div>
                            <div className="w-full xl:w-[30%]  flex items-center justify-center mt-5 ">
                              <Features />
                            </div>
                            <div className="user_details"></div>
                          </Box>
                        </Container>
                      </>
                    )}

                    {paymentSuccess && activeStep === steps.length && (
                      <Container>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: "2rem",
                          }}
                        >
                          <img
                            src={successImage}
                            alt="Success"
                            style={{ height: "60vh", width: "100vh" }}
                          />
                          <h3 style={{ textAlign: "center", color: "#232D8E" }}>
                            Thank You!
                          </h3>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#8A8A8A",
                              marginBottom: "-7%",
                            }}
                          >
                            Your transaction has been successfully processed.
                          </p>
                        </Box>
                      </Container>
                    )}

                    {activeStep > 0 && activeStep !== 3 && (
                      <div className="flex justify-center">
                        <div className="navigation-buttons mt-auto justify-between w-[80%]">
                          {activeStep !== steps.length - 1 && (
                            <button
                              name="submit"
                              className="prev-button rounded-[8px] bg-white border border-[#1ABC9C] text-gray-500 px-4 py-2 shadow-sm transition-colors duration-200 ease-in-out hover:bg-gray-100 disabled:cursor-not-allowed"
                              disabled={activeStep === 0}
                              onClick={handleBack}
                            >
                              Prev
                            </button>
                          )}
                          {activeStep < 6 && !paymentSuccess && (
                            <button
                              id="submit"
                              name="submit"
                              className={`next-button rounded-[8px] bg-[#1ABC9C] text-white px-4 py-2 shadow-sm transition-colors duration-200 ease-in-out hover:bg-[#1ABC8F] disabled:bg-gray-300`}
                              onClick={handleNext}
                              disabled={!data.seller_address || nextLoading}
                            >
                              {nextLoading ? (
                                <CircularProgress size={24} />
                              ) : (
                                "Next"
                              )}
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                  </div>
                </form>}
          </div>
        )}
        <>
          {expandContract && (
            <Modal
              open={expandContract}
              onClose={handleCloseExpandContract}
              aria-labelledby="contract-preview-modal"
              aria-describedby="modal-to-preview-contract"
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={expandContract}>
                <Box
                  sx={{
                    // position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "60vw",
                    maxWidth: "800px",
                    outline: "none",
                    overflowY: "auto",
                    maxHeight: "100vh",
                    position: "relative",
                    zIndex: 10,
                  }}
                >
                  <IconButton
                    onClick={handleCloseExpandContract}
                    sx={{
                      position: "absolute",
                      top: 25,
                      right: 10,
                      color: "#FE6D2B",
                      zIndex: 20,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginBottom: "10px",
                    }}
                  >
                    <IconButton
                      onClick={handleZoomIn}
                      sx={{
                        position: "absolute",
                        top: 25,
                        left: 10,
                        color: "#FE6D2B",
                        zIndex: 20,
                      }}
                    >
                      <ZoomInIcon />
                    </IconButton>
                    <IconButton
                      onClick={handleZoomOut}
                      sx={{
                        position: "absolute",
                        top: 25,
                        left: 40,
                        color: "#FE6D2B",
                        zIndex: 20,
                      }}
                    >
                      <ZoomOutIcon />
                    </IconButton>
                  </div>
                  <div
                    className="image-container"
                    style={{
                      transform: `scale(${zoomLevel})`,
                      transformOrigin: "center",
                    }}
                  >
                    <img src={Page1} alt="Page 1" style={{ width: "100%" }} />
                    <img src={Page2} alt="Page 2" style={{ width: "100%" }} />
                    <canvas id="page3-canvas" style={{ width: "100%" }}></canvas>
                    <img src={Page4} alt="Page 4" style={{ width: "100%" }} />
                  </div>
                </Box>
              </Fade>
            </Modal>
          )}
        </>

      </Container></>
  );
}

export default Sell;
