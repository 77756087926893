/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styles from "./Features.module.css";

const featuresData = [
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/e5e478312dfa9291af5e53639a259f1226d8d18f80accbfc761f91cc3eed8c14?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625",
    text: "No real estate commissions",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/0efc1f2d3a753327159326df2776a0ae201669df7fc908cd07b550b3727a603e?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625",
    text: "AI listing enhancement",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ead2cdd5d9d3e3d2d6c482ee49524b0130b30028a491bb80167c63a8200b11b1?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625",
    text: "Social media push",
  },
  {
    imgSrc:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/ea91203e62d2980d78dd4b014c1d261e93ea07063dd2b26d7f271299278205f7?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625",
    text: "Email marketing integration",
  },
];

const Features = () => (
  <section className={styles.featuresContainer}>
    {featuresData.map((feature, index) => (
      <div key={index} className={styles.feature}>
        <img src={feature.imgSrc} alt={feature.text} />
        <p>{feature.text}</p>
      </div>
    ))}
  </section>
);

export default Features;
