const _config = {
    API_URL: process.env.REACT_APP_API_URL,
    MQTT_WEBSOCKET_URL: process.env.REACT_APP_MQTT_WEBSOCKET_URL,
    STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
    USER_NAME: process.env.REACT_APP_USER_NAME,
    PASSWORD: process.env.REACT_APP_PASSWORD,
    AUTH_BACKEND: process.env.REACT_APP_AUTH_BACKEND,
    GOOGLE_API_KEY: process.env.REACT_APP_GOOGLE_API_KEY,
    IMAGE_BASE_URL: process.env.REACT_APP_IMAGE_BASE_URL,
};

const config = {
    get(key) {
        const value = _config[key];

        if (!value) {
            // console.log(`The ${key} variable not found make sure to pass env variables`);
        } else {
            // console.log(`The ${key} variable is: ${value}`); // Log the value if found
        }
        return value;
    },
};


export default config;
