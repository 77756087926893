export const isAddressValid = address => {
    if (!address) return true; // Allow empty address

    const upperAddress = address.toUpperCase();

    const containsUSA =
        upperAddress.includes('USA') || upperAddress.includes('US') || upperAddress.includes('UNITED STATES');
    const containsState =
        upperAddress.includes('MN') ||
        upperAddress.includes('FL') ||
        upperAddress.includes('NC') ||
        upperAddress.includes('HI') ||
        upperAddress.includes('MINNESOTA') ||
        upperAddress.includes('FLORIDA') ||
        upperAddress.includes('NORTH CAROLINA') ||
        upperAddress.includes('HAWAII');

    return containsUSA && containsState;
};