import React, { useState } from "react";
import pkg_bg from "../../assets/images/pkg_bg.png";
import yard_sign from "../../assets/images/yard_sign.png";
import advertising_creditsIcon from "../../assets/images/advertising_credits.png";
import lockboxes from "../../assets/images/lockboxes.png";
import { FaPlusSquare, FaMinusSquare } from "react-icons/fa";

const packagesArray = [
  {
    topheader: "VALUE PACKAGE",
    background: "#FFFFFF",
    title: "$0.99 Starter",
    price: "$0.99",
    titleColor: "#333333",
    subTitle: "",
    description:
      "Just 99¢ to list your home with basic features. Get nationwide exposure, sell smarter with our entry-level AI package.",
    offers: ["Limited Photos", "AI-targeted ads", "Basic listing"],
    offersColor: "#0056B3",
    buttonTitle: "List For $0.99 Now",
    buttonBackground: "#E67E22",
    packageType: "basic",
  },
  {
    topheader: "MOST POPULAR",
    background: "#ffefc5",
    title: "Gold Marketing  Boost",
    titleColor: "#333333",
    subTitle: "$29.99/Month",
    price: "$29.99",
    subTitleColor: "#0056B3",
    description:
      "Just 99¢ to list your home with basic features. Get nationwide exposure, sell smarter with our entry-level AI package.",
    offers: [
      "10 photos",
      "Listing refresh options",
      "Advanced AI recommendations for better exposure",
    ],
    offersColor: "#333333",
    buttonTitle: "Upgrade To Gold",
    buttonBackground: "#0056B3",
    packageType: "premium",
  },
  {
    topheader: "EXLUSIVE OFFER",
    background: "#2C3E50",
    title: "Casana Market",
    titleColor: "#FFFFFF",
    subTitle: "$59/per month",
    price: "$59",
    subTitleColor: "#FFFFFF",
    description:
      "Our ultimate package—dominate the market with full AI-driven marketing, relocation support, and maximum exposure.",
    offers: ["15 photos", "AI-targeted ads", "Relocation support"],
    offersColor: "#FFFFFF",
    buttonTitle: "Dominate With Marketblast",
    buttonBackground: "#1ABC9C",
    packageType: "deluxe",
  },
];

export default function PackagesList({
  setTotal_price,
  setActiveStep,
  setPackage_type,
  setSelected_items,
}) {
  const [showScreen, setShowScreen] = useState(0);
  const [yardCount, setYardCount] = useState(0);
  const [lockBoxesCount, setLockBoxesCount] = useState(0);
  const [advertising_credits, setAdvertising_credits] = useState(0);
  const [selectedPackage, setSelectedPackage] = useState({
    title: "",
    price: "",
    aminities: [],
  });

  const onClickMinus = (index) => {
    if (index === 0) {
      if (yardCount > 0) {
        setYardCount(yardCount - 1);
      }
    } else if (index === 1) {
      if (lockBoxesCount > 0) {
        setLockBoxesCount(lockBoxesCount - 1);
      }
    } else {
      if (advertising_credits > 0) {
        setAdvertising_credits(advertising_credits - 1);
      }
    }
  };

  const onClickPlus = (index) => {
    if (index === 0) {
      setYardCount(yardCount + 1);
    } else if (index === 1) {
      setLockBoxesCount(lockBoxesCount + 1);
    } else {
      setAdvertising_credits(advertising_credits + 1);
    }
  };

  const pricingSummaryArray = [
    {
      title: "Listing Package",
      value: selectedPackage.price + "/Month",
    },
    {
      title: "Yard Sign",
      value: "$" + (yardCount * 185.62).toFixed(2),
    },
    {
      title: "Lockboxes",
      value: "$" + (lockBoxesCount * 185.62).toFixed(2),
    },
    {
      title: "Advertising Credits",
      value: "$" + advertising_credits * 50,
    },
    // {
    //   title: 'Tax fee',
    //   value: '2%',
    // },
  ];
  const credetsArray = [
    {
      icon: yard_sign,
      title: "Yard Sign",
      price: "$185.62",
      count: yardCount,
    },
    {
      icon: lockboxes,
      title: "Lockboxes",
      price: "$185.62",
      count: lockBoxesCount,
    },
    {
      icon: advertising_creditsIcon,
      title: "Advertising Credits",
      price: "$50",
      count: advertising_credits,
    },
  ];
  const calculateTotal = (pricingArray) => {
    let total = 0;

    pricingArray.forEach((item) => {
      if (item.title !== "Tax fee") {
        const numericValue = parseFloat(item.value.replace(/[^0-9.-]+/g, ""));
        total += numericValue;
      }
    });

    return total;
  };
  const total = calculateTotal(pricingSummaryArray);
  const onClickProceedToPayment = () => {
    if (yardCount) {
      setSelected_items((prev) => [
        ...prev,
        { name: "yard_sign", count: yardCount },
      ]);
    }
    if (lockBoxesCount) {
      setSelected_items((prev) => [
        ...prev,
        { name: "lockbox", count: lockBoxesCount },
      ]);
    }
    if (advertising_credits) {
      setSelected_items((prev) => [
        ...prev,
        { name: "advertising_credits", count: advertising_credits },
      ]);
    }
    setTotal_price(total);
    setActiveStep((prev) => prev + 1);
  };
  return (
    <div className="w-[100%] flex justify-center">
      {showScreen === 0 ? (
        <div className="w-[80%]">
          <h2 className="text-[#333333] text-[32px] font-[700] text-center">
            Choose the Perfect Package for Your Needs
          </h2>
          <p className="text-[#666666] text-[24px] mt-10 text-center">
            Each package is backed by our cutting-edge AI technology, ensuring
            you receive data-driven insights and tools traditionally reserved
            for top-tier real estate professionals.
          </p>
          <div className="flex flex-wrap justify-center gap-10 mt-10">
            {packagesArray.map((item, index) => (
              <div
                key={index}
                style={{
                  background: item.background,
                }}
                className={`rounded-sm shadow-md w-full xl:w-[30%] py-8 h-fit`}
              >
                <div className="relative w-[206px] h-[33px]">
                  <img src={pkg_bg} alt="" className="h-full w-full" />
                  <p className="absolute top-1 left-4 text-center text-white">
                    {item.topheader}
                  </p>
                </div>
                <div className="p-6">
                  <h3
                    style={{
                      color: item.titleColor,
                    }}
                    className={` text-[24px] font-[700]`}
                  >
                    {item.title}
                  </h3>
                  <div className="bg-[#E67E22] w-[47px] h-[4px] mt-5"></div>
                  {item.subTitle && (
                    <p
                      style={{
                        color: item.subTitleColor,
                      }}
                      className="text-[22px] font-[700] text-center mt-5"
                    >
                      {item.subTitle}
                    </p>
                  )}
                  <p
                    className={`${
                      index === 2 ? "text-white" : "text-[#666666]"
                    } text-[16px] mt-5`}
                  >
                    {item.description}
                  </p>
                  <div
                    style={{ color: item.offersColor }}
                    className={` text-[18px] mt-5 font-[600]`}
                  >
                    {item.offers.map((_offer, _i) => (
                      <div key={_i} className="flex  gap-2">
                        ✓<p className="">{_offer}</p>
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={() => {
                      setPackage_type(item.packageType);
                      setSelectedPackage({
                        title: item.title,
                        price: item.price,
                        aminities: item.offers,
                      });
                      setShowScreen(1);
                    }}
                    style={{
                      background: item.buttonBackground,
                    }}
                    className={`mt-5 w-full h-[55px] rounded-md text-center text-white`}
                  >
                    {item.buttonTitle}
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="w-[80%]">
          <h2 className="text-[#333333] text-[32px] font-[700] text-center">
            Review your options before checkout.
          </h2>
          <div className="bg-white p-5 shadow-md w-full mt-10">
            <div className="flex items-center justify-between">
              <h3 className="text-[#333333] text-[22px] font-[600]">
                {selectedPackage.title}
              </h3>
              <p className="text-[#E67E22] [20px] font-[800]">
                {selectedPackage.price}
              </p>
            </div>
            <div className="flex items-center mt-3 justify-between">
              <div className="flex gap-2">
                {selectedPackage.aminities.map((item, index) => (
                  <div
                    key={index}
                    className="flex gap-2 text-[#0056B3] text-[18px] font-[600]"
                  >
                    ✓ {item}
                  </div>
                ))}
              </div>
              <button className="underline text-[#2C3E50] text-[20x] font-[600]">
                Upgrade plan
              </button>
            </div>
          </div>
          <div className="flex flex-col xl:flex-row items-center gap-5 justify-between mt-5">
            {credetsArray.map((item, index) => (
              <div
                key={index}
                className="flex relative items-center gap-5 h-[107px] px-5 w-full xl:w-[33%] bg-white shadow-md rounded-md"
              >
                <img
                  src={item.icon}
                  alt="yard sign"
                  className="h-[67px] w-[67px]"
                />
                <div>
                  <h3 className="text-[#333333] text-[22px] font-[600]">
                    {item.title}
                  </h3>
                  <p className="text-[#E67E22] [20px] font-[800]">
                    {item.price}
                  </p>
                </div>
                <div className="absolute right-5 bottom-5 flex items-center gap-2">
                  <FaMinusSquare
                    onClick={() => onClickMinus(index)}
                    size={30}
                  />
                  <p>{item.count}</p>
                  <FaPlusSquare onClick={() => onClickPlus(index)} size={30} />
                </div>
              </div>
            ))}
          </div>
          <div className="flex justify-center">
            <button
              className={`bg-[#E67E22] w-full xl:w-[33%] mt-5 h-[55px] rounded-md text-center text-white`}
            >
              Add More Items
            </button>
          </div>
          <h2 className="text-[#333333] text-[32px] font-[700] mt-5 xl:mt-0">
            Pricing Summary
          </h2>
          {pricingSummaryArray.map((item, index) => (
            <div
              key={index}
              className="flex w-full justify-between items-center text-[#666666] text-[20px] mt-4"
            >
              <p>{item.title}</p>
              <p>{item.value}</p>
            </div>
          ))}
          <div className="bg-[#E67E22] h-[2px] w-full my-2"></div>
          <div className="flex w-full justify-between items-center text-[#666666] text-[20px] mt-4">
            <p>Total</p>
            <p>${total}</p>
          </div>
          <button
            onClick={onClickProceedToPayment}
            className={`bg-[#1ABC9C] w-full mt-5 h-[55px] rounded-md text-center text-white`}
          >
            Proceed To Payment
          </button>
        </div>
      )}
    </div>
  );
}
