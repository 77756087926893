/**
 * This code was generated by Builder.io.
 */
import * as React from "react";
import styles from "./PaymentMethods.module.css";

function PaymentMethods() {
  return (
    <section className={styles.paymentContainer}>
      <h2 className={styles.paymentHeading}>Payment methods</h2>
      <div className={styles.paymentLogos}>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/55ef2533ceec28c6cd0e2326c9b97a6723e956030434574895661e8d00d0600f?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625"
          className={styles.paymentLogo}
          alt="Payment Method 1"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e883293e2dfbe99232b327c7d0188ec7a63428ba9055bfb4576837ab33057ffd?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625"
          className={styles.paymentLogo}
          alt="Payment Method 2"
        />
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/e10ee87c2a96fba6cd1194479aeb2029b8e8750955923444d3df5ed4779045fa?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625"
          className={styles.paymentLogo}
          alt="Payment Method 3"
        />
      </div>
    </section>
  );
}

export default PaymentMethods;
