import { Container } from "@mui/material";
import React from "react";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import ApartmentOutlinedIcon from "@mui/icons-material/ApartmentOutlined";

const propertyTypes = [
  { display: "Single Family", value: "Single_Family", icon: HouseOutlinedIcon },
  { display: "Condo", value: "Condo", icon: HouseOutlinedIcon },
  { display: "Townhouse", value: "Townhouse", icon: LocationCityOutlinedIcon },
  {
    display: "Multifamily (2+ units)",
    value: "Multifamily",
    icon: ApartmentOutlinedIcon,
  },
];
export default function FormOne(props) {
  return (
    <Container className="flex justify-center  mt-10 rounded-md h-[60vh]  p-[0.8rem]">
      <div className="w-full   xl:w-[60%] flex-col flex items-center gap-10">
        <div className=" w-full flex flex-col items-start gap-2">
          <span
            className="datails font-[800] text-[#001659]"
            htmlFor="seller_address"
          >
            Address:
          </span>
          <input
            ref={props.addressInputRef}
            id="seller_address"
            type="text"
            className="outline-none w-full bg-white p-2 px-3 rounded-md "
            value={props.data.seller_address}
            onChange={props.handleChangeAddress}
            placeholder="Type your address"
          />
          {props.addressError && (
            <p className="text-red-500">* {props.addressError}</p>
          )}
        </div>
        <div className="flex flex-col items-start w-full">
          <span className="datails font-[800] text-[#001659]">
            Property Type
          </span>
          <div className="flex justify-center w-full">
            <div className="flex flex-col items-center gap-2 w-full">
              <div className="grid grid-cols-2 gap-4 w-full xl:w-[460px]">
                {propertyTypes.map((item) => (
                  <div
                    key={item.value}
                    onClick={() => props.setValue(item.value)}
                    style={{
                      backgroundColor:
                        props.value === item.value ? "#E67E22" : "#E7E7E7",
                      color: props.value === item.value ? "#fff" : "black",
                      borderRadius: "8px",
                      padding: "20px 0",
                      textAlign: "center",
                    }}
                    className="flex flex-col items-center justify-center gap-2 border cursor-pointer"
                  >
                    <item.icon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: props.value === item.value ? "#fff" : "#C2BABE",
                      }}
                    />
                    <p>{item.display}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}
