import React, { useState } from "react";
import styles from "./Header.css";

const Header = ({ title }) => {
  return (
    <header className="header text-white">
      <div className="content-container text-white">
        <div className="headerTitle">
          <h1 className="headerText">{title}</h1>
        </div>
        <div className="flex items-center gap-1">
          <a href="/chat" className="customerSupport text-white">
            Customer Support
          </a>

          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/a0e893b03e9ea80514accf32aff3f7cfc3e1662af62583e6ad0b4383a253ffa4?placeholderIfAbsent=true&apiKey=355710932fa240f1a51c362a9b36f625"
            alt="Support"
            className={styles.supportIcon}
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
