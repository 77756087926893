/**
 * This code was generated by Builder.io.
 */
import React from "react";
import styles from "./PriceDetails.module.css";

const PriceDetails = ({ total_price }) => (
    <section className={styles.priceDetailsContainer}>
        {/* <h2>Pricing</h2> */}
        <div className={styles.pricingSection}>
            {/* <div className={styles.pricingItem}>
        <p>Tax fee</p>
        <p className={styles.paymentValue}>2%</p>
      </div> */}
            <div className={styles.pricingItem}>
                <p>Listing charges</p>
                <p className={styles.paymentValue}>${total_price}</p>
            </div>
            <div className={styles.totalPaymentContainer}>
                <p>Total payment</p>
                <p className={styles.paymentValue}>${total_price}</p>
            </div>
        </div>
    </section>
);

export default PriceDetails;
