import React, { useState } from "react";
import { CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CircularProgress from "@mui/material/CircularProgress";
import { Snackbar, Alert } from "@mui/material";
import config from "../../config";
import PaymentMethods from "./PaymentMethods";
import PriceDetails from "./PriceDetails";
const StripeComponent = ({ onPaymentSuccess, onBack, onSetLoading, setSuccess, showError, fullName, total_price, selected_items, package_type }) => {
    const stripe = useStripe();
    const elements = useElements();
    const [loading, setLoading] = useState(false); // Loading state

    const onClickPay = async (payment) => {
        if (!stripe || !elements) {
            return;
        }

        setLoading(true);
        onSetLoading(true);

        const cardNumber = elements.getElement(CardNumberElement);

        try {
            const { paymentMethod, error } = await stripe.createPaymentMethod({
                type: "card",
                card: cardNumber,
            });

            const { token, errorToken } = await stripe.createToken(cardNumber);

            if (error || errorToken) {
                showError("Error creating payment method: " + (error || errorToken).message);
                setLoading(false);
                onSetLoading(false);
                return;
            }

            await sendThePaymentToServer(paymentMethod.id, token.id, payment);
            setSuccess(true);
        } catch (error) {
            showError("Payment error: " + error.message);
            setLoading(false);
            onSetLoading(false);
            showError("Payment error:" + error.message);
        }
    };

    const sendThePaymentToServer = async (paymentMethodId, tokenId, payment) => {
        const token = localStorage.getItem("authUser");
        const url = `${config.get("API_URL")}/api/user/properties/add/checkout`;
        try {
            const payload = {
                transaction_id: localStorage.getItem("transaction_id"),
                token: tokenId,
                code: paymentMethodId,
                payment_amount: total_price,
                package_type,
                selected_items
            }
            console.log("🚀 ~ sendThePaymentToServer ~ payload:", payload)
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token.replace(/^"|"$/g, ""),
                },
                body: JSON.stringify(payload),
            });

            if (response.ok) {
                onPaymentSuccess(200);
            } else {
                throw new Error("Payment not successful");
            }
        } catch (error) {
            showError("Server error during payment: " + error.message);
        } finally {
            setLoading(false);
            onSetLoading(false);
        }
    };

    return (
        <div className="tw-w-full tw-max-w-4xl tw-mx-auto tw-py-8 tw-px-4">
            <div className="tw-text-left tw-mb-6 tw-flex tw-justify-between">
                <PriceDetails total_price={total_price} />
                <PaymentMethods />
            </div>
            <div className="tw-text-left tw-mb-6 tw-flex tw-justify-between">
                <span className="tw-font-sans tw-font-bold tw-text-xl tw-leading-[46px] tw-text-black">
                    Card details
                </span>

            </div>
            <div className="tw-mb-8">
                <label className="tw-font-sans tw-font-bold tw-text-sm tw-text-black tw-block tw-mb-2">Cardholder’s name</label>
                <input
                    type="text"
                    placeholder="Enter name"
                    defaultValue={fullName}
                    className="tw-w-full tw-py-3 tw-px-4 tw-bg-[#F1F1F1] tw-border tw-outline-none focus:tw-border-2 tw-rounded-md focus:tw-border-secondary-500"
                />
            </div>
            <div className="tw-mb-8">
                <label className="tw-font-sans tw-font-bold tw-text-sm tw-text-black tw-block tw-mb-2">Card number</label>
                <div className="tw-w-full tw-py-3 tw-px-4 tw-bg-[#F1F1F1] tw-border tw-rounded-md">
                    <CardNumberElement />
                </div>
            </div>
            <div className="tw-flex tw-gap-4">
                <div className="tw-w-1/2">
                    <label className="tw-font-sans tw-font-bold tw-text-sm tw-text-black tw-block tw-mb-2">Expire</label>
                    <div className="tw-w-full tw-py-3 tw-px-4 tw-bg-[#F1F1F1] tw-border tw-rounded-md">
                        <CardExpiryElement />
                    </div>
                </div>
                <div className="tw-w-1/2">
                    <label className="tw-font-sans tw-font-bold tw-text-sm tw-text-black tw-block tw-mb-2">CVC</label>
                    <div className="tw-w-full tw-py-3 tw-px-4 tw-bg-[#F1F1F1] tw-border tw-rounded-md">
                        <CardCvcElement />
                    </div>
                </div>


            </div>
            <div className="tw-text-sm tw-text-gray-500 tw-mt-6">
                Our payment process employs advanced encryption to protect your financial information and ensure secure, reliable transactions.
            </div>  <div className="tw-flex tw-items-center tw-gap-3 tw-mt-5">
                <button
                    className="tw-p-3 tw-w-[50%] tw-text-[#1ABC9C] tw-border-[#1ABC9C] tw-border tw-rounded-lg disabled:tw-cursor-not-allowed tw-bg-[#fff] disabled:tw-bg-[#C2BABE] "
                    type="button"
                    onClick={onBack}
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    disabled={!stripe || loading}
                    onClick={() => onClickPay("497")}
                    className="tw-px-[5rem]  tw-w-[50%] tw-py-3 tw-bg-[#1ABC9C] tw-text-white tw-rounded-lg disabled:tw-cursor-not-allowed"
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default StripeComponent;
