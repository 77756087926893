import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { useDropzone } from "react-dropzone";
import { LuImagePlus } from "react-icons/lu";
import delete_icon from "../assets/images/delete_icon.png";

function SellImages({
  imagesArr = [],
  setImagesArr,
  labels = [],
  setlabels,
  setImagesForApi,
  imagesForApi,
}) {
  const [files, setFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");

  const handleDrop = (acceptedFiles) => {
    // Filter out non-image files
    const imageFiles = acceptedFiles.filter((file) =>
      file.type.startsWith("image/")
    );
    const nonImageFiles = acceptedFiles.filter(
      (file) => !file.type.startsWith("image/")
    );

    if (nonImageFiles.length > 0) {
      setErrorMessage("Only image files are allowed");
      return;
    }

    setImagesForApi(imageFiles);
    const newFilesWithPreview = imageFiles.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
      })
    );
    setFiles((prevFiles) => [...prevFiles, ...newFilesWithPreview]);
    setImagesArr((prev) => [
      ...prev,
      ...newFilesWithPreview.map((newfile) => newfile.preview),
    ]);
    setlabels((prev) => [...prev, ""]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    accept: "image/*",
    multiple: true,
  });

  const handleDeleteImage = (index) => {
    const newFiles = files.filter(
      (file) => file.preview !== files[index].preview
    );
    const newLabels = labels.filter((label, i) => i !== index);
    setFiles(newFiles);
    setImagesArr(newFiles.map((img) => img.preview));
    setlabels(newLabels);
  };

  return (
    <div className="flex justify-center w-full">
      <div className="w-[70%]">
        <Grid>
          <span className="datails font-[800] text-[#001659]">
            Upload images of your property:
          </span>
          <span className="font-sans font-normal text-sm leading-[150%] text-[#767676] ">
            Please upload clear, high-quality images of your property. Make sure
            the photos are well-lit and capture key areas such as the interior
            rooms, exterior views, and any unique features.
          </span>
          <Box
            {...getRootProps()}
            className="uploadButton border-2 rounded-lg h-[300px] mt-3 border-dashed border-[#FE6D2B] bg-[#FFF9F7] min-h-[130px] flex justify-center items-center width"
            style={{ cursor: "pointer" }}
          >
            <label className="flex flex-col text-gray-400 items-center gap-3 mb-2">
              <LuImagePlus className="text-[2.3rem] text-[#FE6D2B]" />
              <span>Drop your files here</span>
              <button className="bg-[#FE6D2B] rounded-md p-2 text-white">
                Choose File
              </button>
            </label>
          </Box>
          <input {...getInputProps()} accept="image/*" multiple />
        </Grid>
        {errorMessage && (
          <p className="text-red-500 text-[14px] mt-2">{errorMessage}</p>
        )}
        <p className="text-[#4D4D4D] text-[14px] mt-5">
          You can add up to 10 images
        </p>
        {imagesArr.length > 0 && (
          <div className="w-full mb-3">
            {imagesArr.map((img, index) => (
              <div
                key={index}
                className="flex mt-3 items-center justify-between border border-gray-400 rounded-md p-3 "
              >
                <div className="flex items-center gap-5">
                  <img
                    src={img}
                    className="h-[50px] w-[50px] rounded-md object-contain"
                  />
                  <input
                    value={labels[index]}
                    placeholder="Enter Label*"
                    onChange={(e) => {
                      const newLabels = [...labels];
                      newLabels[index] = e.target.value;
                      setlabels(newLabels);
                    }}
                    className="border-b-2 border-black outline-none bg-transparent"
                  />
                </div>
                <img
                  src={delete_icon}
                  onClick={() => handleDeleteImage(index)}
                  className="h-[20px] w-[20px] object-contain cursor-pointer"
                />
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default SellImages;
