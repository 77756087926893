import React from "react";
import real_time_updates from "../../assets/images/real_time_updates.png";
import ep_guide from "../../assets/images/ep_guide.png";
import signature from "../../assets/images/fluent_signature-24-regular.png";

export default function SellWelcomeScreen({ setActiveStep }) {
  const array = [
    {
      title: "Complete the Form",
      description:
        "Follow the prompts to fill out each section of your contract.",
      icon: ep_guide,
    },
    {
      title: "Review and Submit",
      description:
        "Double-check all information for accuracy. Once satisfied, submit the form.",
      icon: real_time_updates,
    },
    {
      title: "Sign the E-Contract",
      description:
        "Once complete, easily sign your contract electronically to finalize the process.",
      icon: signature,
    },
  ];
  return (
    <div className="w-full h-full pb-10">
      <p className="text-center text-[#666666] text-[24px]">
        Here’s a concise guide for filling out the form and signing the
        e-contract:
      </p>
      <h3 className="text-[32px] text-[#333333] font-[700] text-center mt-10">
        {" "}
        Why Get Pre-qualified
      </h3>
      <div className="flex flex-wrap justify-center gap-10 mt-10">
        {array.map((item, index) => (
          <div
            key={index}
            className="bg-white rounded-sm shadow-md flex-row-reverse xl:flex-col gap-5 xl:text-center w-full xl:w-[35%] px-[30px] flex text-start  p-10 items-center"
          >
            <img
              src={item.icon}
              alt={item.icon}
              className="h-[92px] w-[92px]"
            />
            <div className="flex flex-col gap-5">
              <h4 className="text-[22px] text-[#333333] font-[600]">
                {item.title}
              </h4>
              <p className="text-[#666666] text-[20px]">{item.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="flex justify-center mt-10">
        <button
          onClick={() => setActiveStep(1)}
          className="bg-[#1ABC9C] rounded-sm flex items-center gap-2 font-[700] text-[24px] px-5 py-2 text-white"
        >
          <p> Get Started</p>
          <svg
            width="15"
            height="13"
            viewBox="0 0 15 13"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.4968 1.30164L1.5 12.1249"
              stroke="white"
              stroke-width="1.57886"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13.4996 11.875V1.30164H1.7832"
              stroke="white"
              stroke-width="1.57886"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
}
